
;(function(window, document, $) {
	var $win = $(window);
	var $doc = $(document);

	const isTouchDevice = !!(
		'ontouchstart' in window ||
		(window.DocumentTouch && document instanceof DocumentTouch)
	);

	const isMobileBrowser = (function(a) {
		return (
			/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
				a
			) ||
				/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
				a.substr(0, 4)
			)
		);
	})(navigator.userAgent || navigator.vendor || window.opera);

	var swiper = new Swiper('.swiper-container', {
	 	pagination: {
	 		el: '.swiper-pagination',
	 		clickable: true
	 	},
	});

	$('nav')
		.toggleClass('nav-touch', isTouchDevice && isMobileBrowser)
		.toggleClass('nav-no-touch', !(isTouchDevice && isMobileBrowser));

	$('.nav > ul > .parent > a').on('click', function(event) {
		var $ddParent = $(this).parent();

		if ($(this).siblings('.dropdown').length) {
			if ( !$(this).parent().hasClass('dropdown--open') ) {
				event.preventDefault();
			} else if ( $(this).parent().hasClass('dropdown--open') ) {
				event.preventDefault();
			}

			$(this)
				.parent()
				.toggleClass('dropdown--open')
				.siblings()
				.removeClass('dropdown--open');
		}
	});

	$doc.on('click touchstart', function(event) {
		if ( !$(event.target).closest('li.parent').length && !$(event.target).closest('.dropdown').length && !$(event.target).is('.nav > ul > li > a') && !$(event.target).is('.sub-menu') ) {
			// Wait until event bubbles to document
			setTimeout(function() {
				$('.nav > ul > li').removeClass('dropdown--open');
			}, 10);
		}
	});

	$('.video-trigger').on('click', function (event) {
	 	event.preventDefault();
	 	const videoId = $(this).data('video-id');
	 	$.magnificPopup.open({
    		items: [
      			{
        			src: `http://vimeo.com/${videoId}`,
        			type: 'iframe'
      			}
      		]
      	})
	});

	let isNavOpen;

	$('.btn-menu').on('click', function(event) {
		$(this).toggleClass('btn-menu--active');

		$('.header').toggleClass('header--nav-open');
		$('.header__inner').toggleClass('header__inner--open');

  		event.preventDefault();


  		if ( !isNavOpen ) {
  			$('.wrapper').scrollLock('enable');

  			isNavOpen = true

  			setHeaderInnerHeight();
  		} else {
  			$('.wrapper').scrollLock('disable');
  			isNavOpen = false

  			clearHeaderInnerHeight();
  		}
	});

	function setHeaderInnerHeight() {
		const headerHeight = $('.header').height();
		const height = $win.outerHeight() - headerHeight

		$('.header__inner').css({'height': height})
	}
	function clearHeaderInnerHeight() {
		const height = 0
		$('.header__inner').css({'height': 'auto'})
	}

	function detectIE() {
	    var ua = window.navigator.userAgent;
	    var rv = ua.indexOf('rv:');
	    var msie = ua.indexOf('MSIE ');
	    var edge = ua.indexOf('Edge/');
	    var trident = ua.indexOf('Trident/');

	    if (msie > 0) {
	        // IE 10 or older
	        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
	    }

	    if (trident > 0) {
	        // IE 11
	        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
	    }

	    if (edge > 0) {
	        // Edge (IE 12+)
	        return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
	    }

	    return false;
	}

	function fullscreenVideo() {
	    const $videoBg = $('.video-bg--html');
	    const isIE = detectIE() !== false ? true : false;

	    if ( $videoBg.length ) {
	        $win.on('load resize', function() {
	            // EI/Edge doesn't support `object-fit: cover`
	            if ( isIE ) {
	                const $video = $videoBg.find('video');
	                const videoEl = $video[0];

	                let videoBgW = $videoBg.outerWidth();
	                let videoBgH = $videoBg.outerHeight();

	                let videoW = videoEl.videoWidth;
	                let videoH = videoEl.videoHeight;
	                let videoRatio = ( videoW / videoH ).toFixed(2);

	                let widthRatio = (videoBgW / videoW).toFixed(2);
	                let heightRatio = (videoBgH / videoH).toFixed(2);

	                let newW = widthRatio > heightRatio ? videoBgW : Math.ceil( videoBgH * videoRatio );
	                let newH = widthRatio > heightRatio ? Math.ceil( videoBgW / videoRatio ) : videoBgH;

	                $video.css({
	                    'width': newW,
	                    'height': newH
	                });
	            }
	        });
	    }
	}

	const $vimeoVideos = $('.video--vimeo .video__inner');

	if ( $vimeoVideos.length ) {
	    $vimeoVideos.each(function() {
	        let $this = $(this);
	        let $playBtn = $this.siblings('.play');

	        let player = new Vimeo.Player($this.attr('id'), {
	            id: $this.data('video-id')
	        });

	        player.on('loaded', function() {
	            $playBtn.on('click', function(event) {
	                event.preventDefault();

	                const $btn = $(this);

	                $btn
	                    .closest('.video')
	                    .addClass('is-playing');

	                player.play();
	            });
	        });

	        player.on('ended', function() {
	            const $iframe = $(this.element);
	            const $video = $iframe.closest('.video');

	            $video.removeClass('is-playing');
	        });
	    });
	}

	// Test Fullscreen Video
	fullscreenVideo();
})(window, document, window.jQuery);
